import classnames from 'classnames';
import React, { useState } from 'react';
import { AuthProfileProps } from './Profile';

type ProfilePasswordResetProps = Pick<AuthProfileProps, 'resetPassword'>;
export function ProfilePasswordReset({ resetPassword }: ProfilePasswordResetProps): React.ReactElement {
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const [showResetFailure, setShowResetFailure] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendEmailPasswordReset = () => {
    setLoading(true);
    resetPassword().then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setShowResetSuccess(true);
          setTimeout(() => {
            setShowResetSuccess(false);
          }, 6000);
        }
      },
      () => {
        setLoading(false);
        setShowResetFailure(true);
        setTimeout(() => {
          setShowResetFailure(false);
        }, 6000);
      }
    );
  };

  return (
    <div className="row account-settings password-settings">
      <section>
        <label>Password</label>
        <div className="value">***********</div>
        <a className={classnames('link', { disabled: loading })} onClick={sendEmailPasswordReset} role="button">
          Reset
        </a>
      </section>
      {showResetSuccess && (
        <div className="success email-updated">
          You will shortly receive a email with the instructions to reset your password.
        </div>
      )}
      {showResetFailure && <div className="error email-updated">Error, retry later</div>}
    </div>
  );
}
