import { Button, Input } from '@arduino/arc';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { changePassword } from '../api/children';
import { Child } from '../_models/child';
import './ProfileKids.scss';

export interface ProfileKidResetPasswordProps {
  kid: Child;
  setIsOpen: (open: boolean) => void;
}

const passMinLength = 6;
const infoString = `Minimum ${passMinLength} characters`;

export function ProfileKidResetPassword({ kid, setIsOpen }: ProfileKidResetPasswordProps): React.ReactElement {
  const history = useHistory();
  const [newPassword, setnewPassword] = useState('');
  const [error, seterror] = useState('');
  const [info, setinfo] = useState(infoString);

  const changedPass = useCallback((e) => {
    setnewPassword(e.target.value);

    // on input change, clear the error
    seterror('');

    // toggle the info message if password is long enough
    if (e.target.value.length >= passMinLength) {
      setinfo('');
    } else {
      setinfo(infoString);
    }
  }, []);

  const resetPassword = () => {
    if (newPassword.length < 6) {
      seterror(`Length must be ${passMinLength} characters or longer`);
      return;
    }
    changePassword(kid.id.toString(), newPassword)
      .then(() => {
        setIsOpen(false);
        // show snackbar and close the modal
        history.replace('/', { snackbarMessage: `The password was successfully updated.` });
      })
      .catch((err) => {
        seterror(err);
      });
  };

  return (
    <div className="kidsmodal">
      <div>
        Enter a new password for <strong>{kid.username}</strong>.
      </div>
      <p>
        <Input label="New Password" onChange={changedPass} error={error} infoMsg={info} />
      </p>
      <div className="kidsmodal--actions">
        <Button onPress={resetPassword}>Reset Password</Button>
      </div>
    </div>
  );
}
