import React from 'react';
import { ReactComponent as Info } from '../assets/Info.svg';

import './InfoIcon.scss';

const InfoIcon = () => {
  return (
    <div className="icon-wrapper">
      <Info />
    </div>
  );
};

export default InfoIcon;
