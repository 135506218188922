import React, { useState } from 'react';

import {
  IconTravelWorldWebsiteFilled,
  IconSocialFacebookNormal,
  IconSocialTwitterNormal,
  IconSocialGithubNormal,
} from '@arduino/react-icons';

import { User } from '../_models/user';
import classnames from 'classnames';
import { AuthProfileProps } from './Profile';

type ProfileInfoProps = Pick<AuthProfileProps, 'profile' | 'saveProfile'>;

export function ProfileInfo({ profile, saveProfile }: ProfileInfoProps): React.ReactElement {
  const [internalProfile, setInternalProfile] = useState(JSON.parse(JSON.stringify(profile)));

  function resetLocalCopy() {
    setInternalProfile(JSON.parse(JSON.stringify(profile)));
  }

  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  // addInfoMsg controls whether to show "Add" or "Edit" buttons
  let addInfoMsg = 'Add';
  if (profile.public) {
    for (const key in profile.public) {
      if (profile.public[key] && profile.public[key] !== '' && key != 'avatar') {
        addInfoMsg = 'Edit';
      }
    }
  }
  if (profile.social) {
    for (const key in profile.social) {
      if (profile.social[key] && profile.social[key] !== '') {
        addInfoMsg = 'Edit';
      }
    }
  }

  const changeHandler = (section: keyof User) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const editSection = internalProfile[section] as any;

    const editedSection = { ...editSection, [name]: value };

    setInternalProfile({ ...internalProfile, [section]: editedSection });
  };

  // save ensures we are saving only the fields we need
  function save(): void {
    setSaving(true);
    const toSave = {
      public: JSON.parse(JSON.stringify(internalProfile.public)),
      social: internalProfile.social,
    };

    // remove avatar
    delete toSave.public.avatar;
    saveProfile(toSave, ['public', 'social'])
      .then(() => {
        setSaving(false);
        setEditing(false);
      })
      .catch(() => {
        setSaving(false);
      });
  }

  function reset(): void {
    resetLocalCopy();
    setEditing(false);
  }

  return (
    <div className="username-container">
      <h1>
        {profile.username} <span className="normalize-weight">profile</span>
      </h1>
      {!editing && (
        <>
          <h2 className="description-bio">{profile.public?.bio}</h2>
          <div className="social">
            {profile.public?.website && (
              <div className="web">
                <a href={profile.public?.website} target="blank" rel="noopener">
                  <IconTravelWorldWebsiteFilled />
                  {profile.public?.website}
                </a>
              </div>
            )}
            {profile.social?.facebook && (
              <div className="facebook">
                <a href={`https://facebook.com/${profile.social.facebook}`} target="blank">
                  <IconSocialFacebookNormal />
                  facebook.com/{profile.social.facebook}
                </a>
              </div>
            )}
            {profile.social?.twitter && (
              <div className="twitter">
                <a href={`https://twitter.com/${profile.social.twitter}`} target="blank">
                  <IconSocialTwitterNormal />@{profile.social.twitter}
                </a>
              </div>
            )}
            {profile.social?.github && (
              <div className="github">
                <a href={`https://github.com/${profile.social.github}`} target="blank">
                  <IconSocialGithubNormal />
                  github.com/{profile.social.github}
                </a>
              </div>
            )}
          </div>
        </>
      )}

      {editing && (
        <div className="social">
          <div className="bio">
            <textarea
              value={internalProfile.public?.bio}
              name="bio"
              onChange={changeHandler('public')}
              placeholder="Say something about yourself"
            ></textarea>
          </div>
          <div className="web">
            <IconTravelWorldWebsiteFilled />
            <input
              className="legacy"
              value={internalProfile.public?.website}
              name="website"
              onChange={changeHandler('public')}
              placeholder="Your website url"
            />
          </div>
          <div className="facebook">
            <IconSocialFacebookNormal />
            <input
              className="legacy"
              value={internalProfile.social?.facebook}
              name="facebook"
              onChange={changeHandler('social')}
              placeholder="Your facebook profile name"
            />
          </div>
          <div className="twitter">
            <IconSocialTwitterNormal />
            <input
              className="legacy"
              value={internalProfile.social?.twitter}
              name="twitter"
              onChange={changeHandler('social')}
              placeholder="Your twitter username"
            />
          </div>
          <div className="github">
            <IconSocialGithubNormal />
            <input
              className="legacy"
              value={internalProfile.social?.github}
              name="github"
              onChange={changeHandler('social')}
              placeholder="Your github username"
            />
          </div>
        </div>
      )}

      {!editing && (
        <div className="add-info">
          {addInfoMsg === 'Add' && (
            <>Add your social info, your bio and website. Let the community know what you are into!</>
          )}
          <div role="button" className="btn" onClick={() => setEditing(true)}>
            {addInfoMsg} Personal Info
          </div>
        </div>
      )}

      {editing && (
        <div className="add-info">
          <button className="btn secondary cancel-btn" onClick={reset}>
            Cancel
          </button>
          <button className={classnames('btn save-btn', { busy: saving })} disabled={saving} onClick={save}>
            Save
          </button>
        </div>
      )}
    </div>
  );
}
