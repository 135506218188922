import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { AuthContext, AuthContextType } from './context/auth';
import { initAuth } from './HeaderAndFooter';

export const useLastAuthenticatedState = (): [string, React.Dispatch<React.SetStateAction<string>>] => {
  const [value, setValue] = React.useState(localStorage.getItem('lastAuthenticatedState') || '');

  React.useEffect(() => {
    localStorage.setItem('lastAuthenticatedState', value || '');
  }, [value]);

  return [value || '', setValue];
};

type AuthenticationProps = {
  children: React.ReactNode;
};

export function Authentication({ children }: AuthenticationProps): React.ReactElement {
  const location = useLocation();
  const history = useHistory();

  const [lastAuthenticatedState, setLastAuthenticatedState] = useLastAuthenticatedState();

  const [authContext, setAuthContext] = useState<AuthContextType>({
    authenticated: false,
    arduinoConnection: false,
    eduConnection: false,
    coppaConnection: false,
    emailVerified: false,
    googleSignIn: false,
    hasPassword: false,
    mfaEnrolled: false,
    picture: null,
    username: '',
    isMinor: false,
    birthday: '',
  });

  useEffect(() => {
    // if the error param is populated, assume something went wrong during authentication and hijack the current page with the error page
    const params = new URLSearchParams(location.search);
    if (params.get('error') && params.get('state')) {
      return history.replace({ pathname: '/error', search: location.search });
    }
    initAuth().then((authRes: any) => {
      // if the user is not authenticated, prompt to do so
      if (!authRes.isAuthenticated) {
        // if unauthenticathed during the child finalize flow add a querystring param to show a message in the login flow
        if (location.pathname.indexOf('/kids/link') > -1) {
          const urlParams = new URLSearchParams(window.location.search);
          return window.auth.login({ state: location.pathname, finalizeEmail: urlParams.get('finalizeEmail') });
        }
        // if unauthenticathed during the child finalize flow add a querystring param to show a message in the login flow
        if (location.pathname.indexOf('/kids/confirm/teen/') > -1) {
          return window.auth.login({ state: location.pathname, teenConfirmation: true });
        }
        return window.auth.login({ state: location.pathname });
      }

      if (lastAuthenticatedState) {
        // Special case: we were logged in, but the user wanted to switch Arduino account and go back to the previous page
        setLastAuthenticatedState('');
        history.replace(lastAuthenticatedState);
      } else if (authRes.previousState && (authRes.previousState !== location.pathname || location.search.length > 0)) {
        // We were logged out, now we are logged in: go back to the previous page
        history.replace(authRes.previousState);
      }
      window.auth
        .getProfile()
        .then((profile: any) => {
          // update app context setting authenticated user info
          setAuthContext({
            authenticated: true,
            arduinoConnection: profile['http://arduino.cc/connection'] === 'arduino',
            eduConnection: profile['http://arduino.cc/connection'] === 'edu',
            coppaConnection: profile['http://arduino.cc/connection'] === 'coppa',
            emailVerified: !profile['email_verified'] === false,
            googleSignIn: profile['http://arduino.cc/connection'] === 'google-oauth2',
            hasPassword: profile['http://arduino.cc/has_password'] === true,
            mfaEnrolled: profile['http://arduino.cc/enforce_mfa'],
            picture: profile['picture'] || null,
            username: profile['http://arduino.cc/username'],
            isMinor: profile['http://arduino.cc/is_minor'],
            birthday: profile['http://arduino.cc/birthday'],
          });
        })
        .catch((err: any) => {
          console.error(err);
        });
    });
  }, []);

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
}
