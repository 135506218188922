import { axiosClient } from '../context/auth';
import { ConvertToArduinoPayload, ConvertToCoppaPayload } from '../_models/edu';

export async function convertEduToArduino(arduinoPayload: ConvertToArduinoPayload) {
  return axiosClient.post(`${process.env.REACT_APP_API_URL}/v1/conversions/fromEdu/toArduino`, arduinoPayload);
}

export async function convertEduToCoppa(coppaPayload: ConvertToCoppaPayload) {
  return axiosClient.post(`${process.env.REACT_APP_API_URL}/v1/conversions/fromEdu/toCoppa`, coppaPayload);
}
