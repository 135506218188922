import React from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useAuthContext } from '../../context/auth';
import { useLastAuthenticatedState } from '../../Authentication';
import { ActionType } from '../../_models/user';

import { Button } from '@arduino/arc';

import './ChildConfirmLink.scss';
import { ReactComponent as Logo } from '../../assets/logo.svg';

export function ChildConfirmLink() {
  const history = useHistory();
  const location = useLocation();
  const { token } = useParams<{ token: string }>();

  const authContext = useAuthContext();

  console.log(authContext);

  const [, setLastAuthenticatedState] = useLastAuthenticatedState();

  const onSubmit = () => {
    history.push(`/kids/actions/${ActionType.LINK_CHILD}/${token}`);
  };

  const onLogoutClick = () => {
    setLastAuthenticatedState(location.pathname);
    window.auth.logout();
  };

  return (
    <section className="child-confirm-link">
      <div className="logo-wrapper">
        <Logo />
      </div>
      <h1 className="children-form-h1">{`Finalize junior account`}</h1>
      <div className="preamble">
        <p>
          You are logged in as <strong>{authContext.username}</strong>.
        </p>
        <br />
        <p>
          To finalize your child’s account click on continue. To change account, log out and sign in back with the
          preferred one.
        </p>
      </div>
      <div className="form-buttons">
        <Button type="submit" onPress={onSubmit}>
          Continue
        </Button>
        <Button type="button" variant="tertiary" onPress={onLogoutClick}>
          Log out
        </Button>
      </div>
    </section>
  );
}
