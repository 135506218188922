import React, { useState } from 'react';
import { Wrapper, Modal, Password, Button } from '@arduino/arc';

import { AlertMessage } from './AlertMessage';

import './OptionalPassword.scss';

interface OptionalPasswordProps {
  onSave: (newPassword: any) => void;
}

export function OptionalPassword({ onSave }: OptionalPasswordProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [justSaved, setJustSaved] = useState(false);
  const [password, setPassword] = useState<string>();
  const onClick = () => {
    setModalOpen(true);
  };

  const onReset = () => {
    setModalOpen(false);
    onSave(password);
    setJustSaved(true);
  };

  return (
    <Wrapper>
      <div className="optional-password">
        <span>Password</span>
        <span className="reset" onClick={onClick}>
          Reset
        </span>
        {isModalOpen ? (
          <Modal
            title="Reset Kid Password"
            className="optional-password-modal"
            isOpen={isModalOpen}
            isDismissable
            onClose={() => setModalOpen(false)}
          >
            <div className="content-wrapper">
              <div className="description">
                In order to proceed, enter a new password to associate it to this account.
              </div>
              <Password
                infoMsg="Minimum 8 characters"
                label="New Password"
                isRequired
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="button-wrapper">
                <Button onPress={onReset} isDisabled={!password || password.length < 8}>
                  Reset Password
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
      <AlertMessage visible={justSaved}>Password updated!</AlertMessage>
    </Wrapper>
  );
}
