import React, { useState } from 'react';
import { ModalTrigger, PopoverTrigger, TriggerIcon } from '@arduino/arc';
import { IconNavigationMenuMoreVertical } from '@arduino/react-icons';
import { Child } from '../_models/child';
import { ProfileKidDelete } from './ProfileKidDelete';
import { ProfileKidResetPassword } from './ProfileKidResetPassword';
import './ProfileKids.scss';
import { Link } from 'react-router-dom';

export interface ProfileKidRowProps {
  kid: Child;
  popoverOpen: boolean;
  setPopoverOpen: (id: string, state: boolean) => void;
  onDeleteKid: (id: number) => void;
}
export function ProfileKidRow({
  kid,
  popoverOpen,
  setPopoverOpen,
  onDeleteKid,
}: ProfileKidRowProps): React.ReactElement {
  const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  const [resetModalOpen, setresetModalOpen] = useState(false);

  const openModalHandler = (isOpen: boolean, modalRef: 'reset' | 'delete') => {
    switch (modalRef) {
      case 'reset':
        setresetModalOpen(isOpen);
        break;

      case 'delete':
        setdeleteModalOpen(isOpen);
        break;
    }
  };

  return (
    <>
      <img src={kid.avatar} alt={`${kid.username} avatar`} />
      <div className="kidName">{kid.username}</div>
      {!kid.confirmed && <div className="kidPending">PENDING</div>}
      <div className="kidOptions">
        <PopoverTrigger
          isOpen={popoverOpen}
          onOpenChange={(open: boolean) => {
            setPopoverOpen(kid.username, open);
          }}
          TriggerElement={
            <TriggerIcon tag="button" className="removeButtonStyle">
              <IconNavigationMenuMoreVertical />
            </TriggerIcon>
          }
          isDismissable
          popoverPlacement="right"
          popoverStrategy="absolute"
        >
          <div className="kidActions">
            {kid.confirmed && (
              <ModalTrigger
                TriggerElement={
                  <button className="kidActions--element" tabIndex={0}>
                    Reset Junior Password
                  </button>
                }
                isDismissable
                title="Reset Kid Password"
                isOpen={resetModalOpen}
                setIsOpen={(open) => openModalHandler(open, 'reset')}
              >
                <ProfileKidResetPassword kid={kid} setIsOpen={(open) => openModalHandler(open, 'reset')} />
              </ModalTrigger>
            )}
            {!kid.confirmed && (
              <Link to={`/kids/confirm/${kid.id}`} className="kidActions--element" tabIndex={0}>
                Confirm
              </Link>
            )}
            <ModalTrigger
              TriggerElement={
                <button className="kidActions--element" tabIndex={0}>
                  Delete Junior Account
                </button>
              }
              isDismissable
              title="Delete Kid Account"
              isOpen={deleteModalOpen}
              setIsOpen={(open) => openModalHandler(open, 'delete')}
            >
              <ProfileKidDelete
                kid={kid}
                setIsOpen={(open) => openModalHandler(open, 'delete')}
                onDeleteKid={onDeleteKid}
              />
            </ModalTrigger>
          </div>
        </PopoverTrigger>
      </div>
    </>
  );
}
