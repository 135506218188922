import classnames from 'classnames';
import React, { useState } from 'react';
import { ActionType } from '../_models/user';
import { AuthProfileProps } from './Profile';

type ProfileEmailProps = Pick<AuthProfileProps, 'profile' | 'authContext' | 'bookAction'>;

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function ProfileEmail({ profile, authContext, bookAction }: ProfileEmailProps): React.ReactElement {
  const [editing, setEditing] = useState(false);
  const [newEmail, setNewEmail] = useState({ email: '', confirm: '' });
  const [updatingEmail, setUpdatingEmail] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState(false);
  const [errorChangeEmail, setErrorChangeEmail] = useState('');

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setNewEmail({ ...newEmail, [name]: value });
  };

  const reset = () => {
    setEditing(false);
    setNewEmail({ email: '', confirm: '' });
  };

  const saveEmail = (): void => {
    setUpdatingEmail(true);
    bookAction({ new_email: newEmail.email }, ActionType.CHANGE_EMAIL).then(
      () => {
        setEditing(false);
        setUpdatingEmail(false);
        setUpdatedEmail(true);

        setTimeout(() => {
          setUpdatedEmail(false);
        }, 10000);
      },
      (error) => {
        if (error?.response?.status === 409) {
          setErrorChangeEmail('This e-mail address is already being used.');
        } else {
          setErrorChangeEmail('Error while changing the email, please retry later');
          console.error(error);
        }
        setUpdatingEmail(false);

        setTimeout(() => {
          setErrorChangeEmail('');
        }, 10000);
      }
    );
  };

  return (
    <div className="row account-settings">
      <section>
        <label>Email</label>
        {!editing && <div className="value">{profile?.contact?.email}</div>}
        {editing && (
          <div className="value">
            <div className="pass-container">
              <input
                className="legacy"
                type="email"
                placeholder="New Email"
                minLength={8}
                required
                name="email"
                value={newEmail.email}
                onChange={changeHandler}
              />
            </div>
            <div className="pass-container">
              <input
                className="legacy"
                type="email"
                placeholder="Confirm New Email"
                minLength={8}
                required
                name="confirm"
                value={newEmail.confirm}
                onChange={changeHandler}
              />
              <p className="note">A confirmation link will be sent to your new e-mail.</p>
            </div>
          </div>
        )}
        {!editing && authContext.arduinoConnection && (
          <div className="change-link">
            <a className="link" onClick={() => setEditing(true)} role="button">
              Change
            </a>
          </div>
        )}
      </section>
      {editing && (
        <div className="button-wrapper">
          <button className="btn secondary" onClick={reset}>
            Cancel
          </button>
          <button
            className={classnames('btn', { busy: updatingEmail })}
            disabled={
              updatingEmail ||
              !validateEmail(newEmail.email) ||
              newEmail.confirm !== newEmail.email ||
              newEmail.email === profile?.contact?.email ||
              newEmail.email.length < 8
            }
            onClick={saveEmail}
          >
            Save
          </button>
        </div>
      )}
      {updatedEmail && (
        <div className="success email-updated">
          You will shortly receive a confirmation email to your new address, click the link in the email to confirm the
          change.
        </div>
      )}
      {errorChangeEmail && <div className="error email-updated">{errorChangeEmail}</div>}
    </div>
  );
}
