function detach() {
  const header = document.getElementById('header') as any;
  while (header.firstChild) {
    header.removeChild(header.firstChild);

    delete header.dataset.ref;
  }

  const footer = document.getElementById('footer') as any;
  while (footer.firstChild) {
    footer.removeChild(footer.firstChild);
  }
  delete footer.dataset.ref;
}

export function initHeaderAndFooter(style = 'arduino', coppaCompliant = false) {
  // Check currenty-rendered header-footer style
  let currentStyle;
  if (window['footer'] && window['footer']['opts']) {
    currentStyle = window['footer']['opts']['style'];
  }

  // Don't do anything if it's the same
  if (style === currentStyle) {
    return;
  }

  // Destroy the current header-footer if we need to change its style
  if (currentStyle !== undefined) {
    detach();
  }

  // Only render the header for "regular" profile pages
  if (style === 'arduino') {
    window['header'] = new window.arduinoHF['Header'](document.getElementById('header'), window.auth, {
      env: process.env.REACT_APP_HF_ENV,
      style: 'arduino',
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.getElementById('header')!.style.display = style === 'arduino' ? 'block' : 'none';

  window['footer'] = new window.arduinoHF['Footer'](document.getElementById('footer'), {
    env: process.env.REACT_APP_HF_ENV,
    coppaCompliant,
    style,
  });
}

export async function initAuth(): Promise<any> {
  return {
    ...(await window.auth.handleAuthentication()),
    isAuthenticated: await window.auth.isAuthenticated(),
  };
}
