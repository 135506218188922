import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { executeAction } from '../api/actions';
import { ActionType } from '../_models/user';

import failImg from '../assets/fail.svg';
import welcomeImg from '../assets/welcome.svg';
import { LoadingSpinner } from './LoadingSpinner';
import { useAuthContext } from '../context/auth';

export function ExecuteAction(): React.ReactElement {
  const { action, token } = useParams<{ action: string; token: string }>();

  const authContext = useAuthContext();
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!authContext.authenticated) {
      return;
    }

    executeAction(token, action as ActionType)
      .then((res) => {
        if (action === ActionType.LINK_CHILD) {
          history.replace(`/kids/confirm/${res.data.context['child_id']}`, { fromEmail: true });
          return;
        }
        setSuccess(true);
        setFinished(true);
      })
      .catch((error: Response | any) => {
        setFinished(true);
        setSuccess(false);
        if (action === ActionType.CHANGE_EMAIL && error?.response?.status === 409) {
          setError('This e-mail address was already used.');
        } else {
          try {
            setError(JSON.parse(error).message);
          } catch {
            setError(error.toString());
          }
        }
      });
  }, [authContext.authenticated]);

  function goBackToProfile() {
    window.location.replace(`${window.location.protocol}//${window.location.hostname}`);
  }

  if (!finished) {
    return <LoadingSpinner />;
  }

  return (
    <div className="profile-page">
      <div className="confirm-section">
        {success && action === 'change_email' && (
          <div>
            <img src={welcomeImg} />
            <h2>Your Email has been successfully changed.</h2>
          </div>
        )}
        {success && action === 'disable_mfa' && (
          <div>
            <img src={welcomeImg} />
            <h2>The Two Factor authentication has been successfully disabled.</h2>
          </div>
        )}
        {success && action === 'delete_user' && (
          <div>
            <img src={failImg} />
            <h2>Your Arduino Account has been successfully deleted.</h2>
            <p>
              You can{' '}
              <a href={`${window.location.protocol}//${window.location.hostname}`} className="link">
                Sign Up
              </a>{' '}
              again anytime
            </p>
          </div>
        )}
        {!success && (
          <div>
            <img src={failImg} />
            <h2>There&apos;s been an error during this action.</h2>
            <p>{error}</p>
          </div>
        )}

        {(action !== 'delete_user' || !success) && (
          <div>
            <button className="btn" onClick={goBackToProfile}>
              Go back to your profile
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
