import React from 'react';

import { Button } from '@arduino/arc';

import './Error.scss';
import ohnoImg from '../assets/ohno.svg';

const defaultMessage = 'For some unknown reason the sign in process failed, please try again.';
const validMessages = {
  // FIXME: raised when a user tries to register while they have a legacy invite tied to their e-mail address
  // it's a stopgap solution
  LEGACY_INVITE: `The e-mail address you chose is tied to an education account, please accept the invite you received via e-mail before continuing.`,
};

export function Error(): React.ReactElement {
  const params = new URLSearchParams(window.location.search);

  const errorMessage =
    params.get('error_description') === 'LEGACY_INVITE' ? validMessages.LEGACY_INVITE : defaultMessage;

  const retry = () => {
    window.auth.logout();
  };

  return (
    <div className="error-layout">
      <div className="main-container">
        <main className="main">
          <section>
            <h1>Sorry, something went wrong</h1>
            <div className="message">{errorMessage}</div>
            <div className="buttons">
              <Button type="submit" onPress={retry}>
                TRY AGAIN
              </Button>
            </div>
            <img src={ohnoImg} alt="oh no" />
          </section>
        </main>
      </div>
    </div>
  );
}
