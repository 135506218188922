import React from 'react';

import { ProfileInfo } from './ProfileInfo';

import './ProfileHeader.scss';
import { ProfileAvatar } from './ProfileAvatar';
import { AuthProfileProps } from './Profile';

type ProfileHeaderProps = Pick<AuthProfileProps, 'profile' | 'saveProfile' | 'authContext'>;
export function ProfileHeader({ profile, saveProfile, authContext }: ProfileHeaderProps): React.ReactElement {
  return (
    <section className="profile-header">
      <div className="title">
        <ProfileAvatar profile={profile} saveProfile={saveProfile} authContext={authContext} />
        <div className="profileinfo">
          <ProfileInfo profile={profile} saveProfile={saveProfile} />
        </div>
      </div>
    </section>
  );
}
