import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch, useParams, useLocation } from 'react-router-dom';

import { LoadingSpinner } from '../LoadingSpinner';
import { getChild } from '../../api/children';
import { Child, ChildPayload } from '../../_models/child';

import { ChildForm } from './ChildForm';
import { ChildLayout } from './ChildLayout';
import { ChildConfirmLink } from './ChildConfirmLink';
import { ChildConsent } from './ChildConsent';
import { ConvertTeenToAdult } from './ConvertTeenToAdult';
import { ExecuteAction } from '../ExecuteAction';

import { axiosClient } from '../../context/auth';

import { AvatarInfo } from '@arduino/arc/dist/components/avatar/AvatarPicker';
import { ConvertBrokenCoppa } from './ConvertBrokenCoppa/ConvertBrokenCoppa';
import { AgeGate } from './ConvertBrokenCoppa/AgeGate';
import { RegularRegister } from './ConvertBrokenCoppa/RegularRegister';
import { CoppaRegister } from './ConvertBrokenCoppa/CoppaRegister';

function ChildConfirm() {
  const { id } = useParams<{ id: string }>();
  const [child, setChild] = useState({} as Child);
  const [avatar, setAvatar] = useState({} as AvatarInfo);
  const { state } = useLocation();

  useEffect(() => {
    (async () => {
      const c = await getChild(id);
      const avatarData = (await axiosClient.get(c.avatar)).data;
      setAvatar({
        name: id,
        avatar: `data:image/svg+xml;base64,${window.btoa(avatarData)}`,
      });
      setChild(c);
    })();
  }, []);

  if (!child.id) {
    return <LoadingSpinner />;
  }

  const childData = {
    username: child.username,
    birthday: new Date(child.birthday),
    avatar: avatar.name,
  } as ChildPayload;

  return (
    <ChildLayout hideBackButton={state && (state as any).fromEmail}>
      <ChildForm
        title="Finalize your child's account"
        description="Your child has picked these credentials for their account. Please review them and make sure they are safe and correct."
        optionalPassword={true}
        childData={childData}
        currentAvatar={avatar}
        nextRoute={`/kids/confirm/${id}/consent`}
      />
    </ChildLayout>
  );
}

export function Children(): React.ReactElement {
  return (
    <Switch>
      <Route component={ConvertTeenToAdult} path="/kids/convert" exact />
      <Route
        path="/kids/convert-broken"
        render={({ match: { url } }) => (
          <>
            <Route path={`${url}/`} component={ConvertBrokenCoppa} exact />
            <Route path={`${url}/age-gate`} component={AgeGate} />
            <Route path={`${url}/regular/:birthday`} component={RegularRegister} />
            <Route path={`${url}/coppa/:birthday`} component={CoppaRegister} />
          </>
        )}
      />
      <Route exact path="/kids/new">
        <ChildLayout>
          <ChildForm
            title="Create a new <span>junior account</span>"
            description="Pick an avatar, username and a password for your child. This account will be linked to your email address."
            optionalPassword={false}
            nextRoute={'/kids/new/consent'}
          />
        </ChildLayout>
      </Route>
      <Route path="/kids/new/consent">
        <ChildLayout>
          <ChildConsent isCoppa={true} />
        </ChildLayout>
      </Route>
      <Route path="/kids/actions/:action/:token">
        <ChildLayout hideBackButton>
          <ExecuteAction />
        </ChildLayout>
      </Route>
      <Route path="/kids/link/:token">
        <ChildLayout hideBackButton>
          <ChildConfirmLink />
        </ChildLayout>
      </Route>
      <Route exact path="/kids/confirm/:id">
        <ChildConfirm />
      </Route>
      <Route path="/kids/confirm/:id/consent">
        <ChildLayout>
          <ChildConsent isCoppa={true} />
        </ChildLayout>
      </Route>
      <Route path="/kids/confirm/teen/:id">
        <ChildLayout>
          <ChildConsent isCoppa={false} />
        </ChildLayout>
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
