import { axiosClient } from '../context/auth';
import { ActionType } from '../_models/user';

const bookUrl = process.env.REACT_APP_API_URL + '/v1/actions/book/';
const actionUrl = process.env.REACT_APP_API_URL + '/v1/actions/execute/';

export async function executeAction(token: string, action: ActionType) {
  return axiosClient.post(actionUrl, {
    token: token,
    action: action,
  });
}

export async function bookAction(context: Record<string, any>, action: ActionType, authenticated = false) {
  return axiosClient.post(bookUrl, {
    context,
    action,
    authenticated,
  });
}
