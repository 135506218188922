import classnames from 'classnames';
import React, { useState } from 'react';
import { ActionType } from '../_models/user';
import { AuthProfileProps } from './Profile';

type ProfileSecurityProps = Pick<AuthProfileProps, 'authContext' | 'bookAction'>;

export function ProfileSecurity({ authContext, bookAction }: ProfileSecurityProps): React.ReactElement {
  const [errorMfa, setErrorMfa] = useState(false);
  const [emailMfaSent, setEmailMfaSent] = useState(false);
  const [loading, setLoading] = useState(false);

  function enable() {
    window.location.replace(process.env.REACT_APP_MFA_URL!);
  }

  function disableMfa() {
    setLoading(true);
    bookAction({}, ActionType.DISABLE_MFA).then(
      () => {
        setLoading(false);
        setEmailMfaSent(true);
        setTimeout(() => {
          setEmailMfaSent(false);
        }, 6000);
      },
      () => {
        setLoading(false);
        setErrorMfa(true);
        setTimeout(() => {
          setErrorMfa(false);
        }, 6000);
      }
    );
  }

  return (
    <>
      <div className="section-title">Security</div>
      <div className="section-content security">
        <div className="two-step-action">
          <label>2-Step Verification</label>
          {!authContext.mfaEnrolled && (
            <div className="change-link">
              <a className="link" onClick={enable} role="button">
                Activate
              </a>
            </div>
          )}
          {authContext.mfaEnrolled && (
            <div className="change-link">
              <a className={classnames('link', { disabled: loading })} onClick={disableMfa} role="button">
                Disable
              </a>
            </div>
          )}
        </div>
        {emailMfaSent && (
          <div className="success">
            You will shortly receive a confirmation email to your email address, click the link in the email to confirm
            the change.
          </div>
        )}
        {errorMfa && (
          <div className="error">Error while disabling the two factor authentication, please retry later</div>
        )}
        <p className="intro">
          Each time you sign in to your Arduino account, you’ll need your password and a verification code: this will
          assure that even if someone else gets your password, it won’t be able to sign in to your account.
        </p>
      </div>
    </>
  );
}
