import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { convertEduToCoppa } from '../../../api/conversion';
import { ConvertToCoppaPayload } from '../../../_models/edu';

import { Button, Password, Input, AvatarPicker, TooltipTrigger } from '@arduino/arc';
import { differenceInYears, startOfToday, isValid } from 'date-fns';

import './CoppaRegister.scss';
import { ChildLayout } from '../ChildLayout';
import { IconOperationReloadArrow } from '@arduino/react-icons';
import { SendParentEmailForm } from './SendParentEmailForm';
import { getAvatars, getRandomUsername } from '../../../api/children';
import { AvatarInfo } from '@arduino/arc/dist/components/avatar/AvatarPicker';
import InfoIcon from '../../InfoIcon';
import { LoadingSpinner } from '../../LoadingSpinner';

interface errorsInterface {
  password?: string;
  birthday?: string;
}

export function CoppaRegister() {
  const { birthday } = useParams<{ birthday: string }>();
  const [childInfo, setChildInfo] = useState<ConvertToCoppaPayload>({
    username: '',
    password: '',
    parent_email: '',
    birthday: birthday,
    avatar: '',
  });
  const [avatarList, setAvatarList] = useState<AvatarInfo[] | undefined>();
  const [errors, setErrors] = useState({} as errorsInterface);
  const [serverError, setServerErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [showParentEmailForm, setShowParentEmailForm] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!birthday && isValid(new Date(birthday))) {
      history.push('/error');
    }

    if (differenceInYears(startOfToday(), new Date(birthday)) >= 14) {
      history.push('/kids/convert-broken/age-gate');
    }
  }, [birthday]);

  const setField = (field: string, val: any) => {
    setChildInfo((prev) => ({ ...prev, [field]: val }));
  };

  const setRandomUsername = async () => {
    try {
      const newUsername = await getRandomUsername();
      setField('username', newUsername);
    } catch (e) {
      //setFetchError(e);
    }
  };

  useEffect(() => {
    (async () => {
      const avatars = await getAvatars();
      if (!childInfo.username) {
        const username = await getRandomUsername();
        setField('avatar', avatars[0].name);
        setField('username', username);
      }
      setAvatarList(avatars);
    })();
  }, []);

  const validateForm = () => {
    const errors = {
      password: childInfo.password ? undefined : 'The password is required',
    };
    if (childInfo.password && childInfo.password?.length < 8) {
      errors.password = 'Minimum 8 characters';
    }

    setErrors(errors);
    return !Object.values(errors).some(Boolean);
  };

  const submitChild = async (parentEmail: any) => {
    setServerErrors('');
    setLoading(true);
    try {
      await convertEduToCoppa({ ...childInfo, parent_email: parentEmail });
      window.location.href = process.env.REACT_APP_COPPA_PROFILE_URL!;
    } catch (e: any) {
      setServerErrors(
        e.response.data.message || 'Error while finalize the conversion, retry later or contact the support'
      );
    } finally {
      setLoading(false);
    }
  };

  const submitForm = async () => {
    if (!validateForm()) {
      return;
    }

    setShowParentEmailForm(true);
  };

  if (showParentEmailForm) {
    return (
      <ChildLayout
        isCoppa={true}
        onBack={() => {
          setField('password', '');
          setServerErrors('');
          setShowParentEmailForm(false);
        }}
      >
        <SendParentEmailForm onSubmit={submitChild} error={serverError} loading={loading} invalidEmail={''} />
      </ChildLayout>
    );
  }

  return (
    <ChildLayout hideBackButton isCoppa={true}>
      {!childInfo.username || !avatarList ? (
        <LoadingSpinner />
      ) : (
        <section className="convert-coppa">
          <h1 className="children-form-h1">Create a junior account</h1>
          <p className="description">{'Choose your avatar image, username and password.'}</p>
          <div className="form">
            <div className="avatar">
              <AvatarPicker
                isEdit
                avatarSize={50}
                avatar={childInfo.avatar}
                avatarList={avatarList}
                modalTitle={'Choose an avatar'}
                onSelect={(avatar) => setField('avatar', avatar)}
                className="avatar-picker"
              />
            </div>
            <div className="form-input username-input">
              <Input
                isReadOnly
                value={childInfo.username}
                label={"Kid's Username"}
                buttons={[<IconOperationReloadArrow key="icon" onClick={setRandomUsername} />]}
              />
              <div className="tooltip-row-wrapper">
                <TooltipTrigger
                  TriggerElement={
                    <div>
                      <InfoIcon />
                    </div>
                  }
                  className="popover"
                  tooltipPlacement="top-start"
                  tooltipStrategy="absolute"
                  tooltipType={'popover'}
                >
                  <span>
                    The username is created for you automatically, but you can generate new ones until you find the best
                    one. You will use this username to log in to Arduino, and other users will see it when you post
                    projects.
                  </span>
                </TooltipTrigger>
              </div>
            </div>
            <div className="form-input">
              <Password
                label="Password"
                error={errors['password']}
                isRequired
                infoMsg={'Minimum 8 characters'}
                onChange={(e) => setField('password', e.target.value)}
              />
              <div className="tooltip-row-wrapper"></div>
            </div>
            <div className="form-buttons">
              <Button type="submit" onPress={submitForm} className="next-btn" loading={loading}>
                {'Next'}
              </Button>
            </div>
          </div>
        </section>
      )}
    </ChildLayout>
  );
}
