import { axiosClient } from '../context/auth';
import { ConsentInfo } from '../_models/user';

export async function getConsent() {
  return axiosClient.get(`${process.env.REACT_APP_CONSENT_URL}`);
}

export async function saveConsent(payload: ConsentInfo) {
  payload = Object.assign(payload, { privacy: true });
  return axiosClient.put(`${process.env.REACT_APP_CONSENT_URL}`, payload);
}
