import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import Header from './ChildHeader';
import { LoadingSpinner } from '../LoadingSpinner';

import './ChildLayout.scss';
import { useAuthContext } from '../../context/auth';

interface ChildLayoutProps {
  children: React.ReactElement;
  onBack?: () => void;
  hideBackButton?: boolean;
  isCoppa?: boolean;
}

export function ChildLayout({ children, onBack, hideBackButton, isCoppa }: ChildLayoutProps) {
  const history = useHistory();
  const backAction =
    onBack ||
    (() => {
      history.goBack();
    });

  const authContext = useAuthContext();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!authContext.authenticated) {
      return;
    }
    setReady(true);
  }, [authContext.authenticated]);

  return (
    <>
      <div className="child-layout">
        <div className={classnames('main-container', { coppa: isCoppa })}>
          <Header onBack={backAction} hideBackButton={hideBackButton} />
          <main className="main">{!ready ? <LoadingSpinner /> : children}</main>
        </div>
      </div>
    </>
  );
}
