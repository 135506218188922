import React, { useEffect } from 'react';
import { Button } from '@arduino/arc';
import { useHistory } from 'react-router-dom';
import { ChildLayout } from '../ChildLayout';
import { useAuthContext } from '../../../context/auth';

import './ConvertBrokenCoppa.scss';

export function ConvertBrokenCoppa() {
  const history = useHistory();
  const authContext = useAuthContext();

  useEffect(() => {
    if (!authContext.authenticated) {
      return;
    }
    // Check if is a broken user
    if (!authContext.eduConnection) {
      history.push('/error');
    }
  }, [authContext.authenticated, authContext.isMinor, authContext.birthday]);

  return (
    <>
      <ChildLayout hideBackButton>
        <section className="convert-broken-landing">
          <h1 className="children-form-h1">{'Update your account information'}</h1>
          <div className="disclaimer">
            <p className="description">
              {'We are improving account security and need to update your account with new information.'}
            </p>
            <p className="description">
              {'You will be asked to choose a new username, password and link your account to an email address.'}
            </p>
            <p className="description">
              {'These changes will make it easier to sign in, manage your account, and contact support services. '}
            </p>
          </div>
          <Button
            type="button"
            onPress={() => {
              history.push('/kids/convert-broken/age-gate');
            }}
          >
            Continue
          </Button>
          <div className="spacer"></div>
        </section>
      </ChildLayout>
    </>
  );
}
