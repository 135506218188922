import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { convertEduToArduino } from '../../../api/conversion';
import { ConvertToArduinoPayload } from '../../../_models/edu';

import { Checkbox, Button, Password, Input } from '@arduino/arc';
import { differenceInYears, startOfToday, isValid } from 'date-fns';

import './RegularRegister.scss';
import { ChildLayout } from '../ChildLayout';
import { IconNavigationCloseOutlineFilled } from '@arduino/react-icons';
import { SendParentEmailForm } from './SendParentEmailForm';

interface errorsInterface {
  email?: string;
  username?: string;
  password?: string;
  privacy?: string;
}

export function RegularRegister() {
  const { birthday } = useParams<{ birthday: string }>();
  const [regularInfo, setRegularInfo] = useState<ConvertToArduinoPayload>({
    username: '',
    password: '',
    email: '',
    birthday: birthday,
    consent: {
      marketing: false,
      tracking: false,
      privacy: false,
      terms_and_conditions: true,
      newsletter: false,
    },
  });
  const [errors, setErrors] = useState({} as errorsInterface);
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isTeen, setIsTeen] = useState(false);
  const [showParentEmailForm, setShowParentEmailForm] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!birthday && isValid(new Date(birthday))) {
      history.push('/error');
    }

    if (differenceInYears(startOfToday(), new Date(birthday)) < 16) {
      setIsTeen(true);
    }
  }, [birthday]);

  const setField = (field: string, val: any) => {
    setRegularInfo((prev) => ({ ...prev, [field]: val }));
  };

  const validateForm = () => {
    const re =
      /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

    const errors = {
      password: regularInfo.password ? undefined : 'The password is required',
      username: regularInfo.username ? undefined : 'The username is required',
      email: regularInfo.email ? undefined : 'The email is required',
      privacy: regularInfo.consent?.privacy || isTeen ? undefined : 'You must accept the privacy policy',
    };
    if (regularInfo.password && regularInfo.password?.length < 8) {
      errors.password = 'Minimum 8 characters';
    }

    if (regularInfo.username && regularInfo.username?.length < 3) {
      errors.password = 'Minimum 3 characters';
    }

    if (regularInfo.email && !re.test(String(regularInfo.email).toLowerCase())) {
      errors.email = 'Must be a valid email';
    }

    setErrors(errors);
    return !Object.values(errors).some(Boolean);
  };

  const handleError = (error: any) => {
    // If the user try to convert with an already registered email/nickname show a custom error message
    if (error.indexOf('conflict') > -1) {
      setShowParentEmailForm(false);
      setServerError('conflict');
    } else {
      setServerError(error);
    }
  };

  const submitTeen = async (parentEmail: string) => {
    setServerError('');
    setLoading(true);
    try {
      await convertEduToArduino({ ...regularInfo, parent_email: parentEmail });

      window.auth.logout({ redirect_uri: `${window.location.protocol}//${window.location.hostname}` });
    } catch (e: any) {
      handleError(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const submitForm = async () => {
    setServerError('');
    if (!validateForm()) {
      return;
    }

    if (isTeen) {
      setShowParentEmailForm(true);
      return;
    }

    setLoading(true);
    try {
      await convertEduToArduino(regularInfo);
      window.auth.logout({ redirect_uri: `${window.location.protocol}//${window.location.hostname}` });
    } catch (e: any) {
      handleError(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  if (showParentEmailForm) {
    return (
      <ChildLayout
        onBack={() => {
          setField('password', '');
          setServerError('');
          setShowParentEmailForm(false);
        }}
      >
        <SendParentEmailForm
          onSubmit={submitTeen}
          error={serverError}
          loading={loading}
          invalidEmail={regularInfo.email}
        />
      </ChildLayout>
    );
  }

  return (
    <ChildLayout hideBackButton>
      <section className="convert-regular">
        <h1 className="children-form-h1">Sign up to Arduino</h1>
        <div className="form">
          <div className="form-input">
            <Input
              name="email"
              label={'Enter your email'}
              error={errors['email']}
              isRequired
              value={regularInfo.email}
              onChange={(e) => setField('email', e.target.value)}
            />
          </div>
          <div className="form-input">
            <Input
              name="username"
              label={'Choose a Username'}
              error={errors['username']}
              isRequired
              value={regularInfo.username}
              onChange={(e) => setField('username', e.target.value)}
            />
          </div>

          <div className="form-input">
            <Password
              label="Choose a password"
              error={errors['password']}
              isRequired
              infoMsg={'Minimum 8 characters'}
              onChange={(e) => setField('password', e.target.value)}
            />
          </div>

          {isTeen ? null : (
            <div className="consents">
              <Checkbox
                label="I have read the [Privacy Policy](https://www.arduino.cc/PrivacyPolicy/) and accept the [Terms of Service](https://www.arduino.cc/en/Main/TermsOfService)."
                isRequired={true}
                onChange={(e) => setField('consent', { ...regularInfo.consent, privacy: e.target.checked })}
                error={errors['privacy']}
              />
              <Checkbox
                label="I would like to receive the Arduino newsletter."
                onChange={(e) => setField('consent', { ...regularInfo.consent, newsletter: e.target.checked })}
              />
              <Checkbox
                label="I would like to receive emails about special deals and commercial offers from Arduino."
                onChange={(e) => setField('consent', { ...regularInfo.consent, marketing: e.target.checked })}
              />
              <Checkbox
                label="I want to see personalised commercial offers from Arduino based on my browsing and purchasing behaviour."
                onChange={(e) => setField('consent', { ...regularInfo.consent, tracking: e.target.checked })}
              />
            </div>
          )}
          {serverError ? (
            serverError === 'conflict' ? (
              <div className="alert-box with-action">
                <IconNavigationCloseOutlineFilled />
                <span>
                  {
                    'This email address/username already belongs to an Arduino account. Please create a new account or contact us to attach your kit to this account.'
                  }
                </span>
                <a className="contact-us" href="https://www.arduino.cc/en/contact-us/" target="_blank" rel="noreferrer">
                  Contact us
                </a>
              </div>
            ) : (
              <div className="alert-box">
                <IconNavigationCloseOutlineFilled />
                <span>{serverError}</span>
              </div>
            )
          ) : null}
          <div className="form-buttons">
            <Button type="submit" onPress={submitForm} className="next-btn" loading={loading}>
              {isTeen ? 'Next' : 'Sign Up'}
            </Button>
          </div>
        </div>
      </section>
    </ChildLayout>
  );
}
