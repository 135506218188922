import axios from 'axios';
import { createContext, useContext } from 'react';

export type AuthContextType = {
  authenticated: boolean;
  arduinoConnection: boolean;
  eduConnection: boolean;
  coppaConnection: boolean;
  emailVerified: boolean;
  googleSignIn: boolean;
  hasPassword: boolean;
  mfaEnrolled: boolean;
  isMinor: boolean;
  picture: string | null;
  username: string;
  birthday: string;
};

export const AuthContext = createContext<AuthContextType>({
  authenticated: false,
  arduinoConnection: false,
  eduConnection: false,
  coppaConnection: false,
  emailVerified: false,
  googleSignIn: false,
  hasPassword: false,
  mfaEnrolled: false,
  isMinor: false,
  picture: null,
  username: '',
  birthday: '',
});

export function useAuthContext(): AuthContextType {
  return useContext(AuthContext);
}

export const axiosClient = axios.create();

axiosClient.interceptors.request.use(async (config) => {
  try {
    config.headers = { Authorization: `Bearer ${await window.auth.getAccessToken()}`, ...(config.headers || {}) };
  } catch (e) {
    window.auth.login();
  }
  return config;
});
