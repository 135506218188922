import classnames from 'classnames';
import React, { useState } from 'react';
import { AuthProfileProps } from './Profile';

type ProfilePasswordProps = Pick<AuthProfileProps, 'changePassword'>;
export function ProfilePassword({ changePassword }: ProfilePasswordProps): React.ReactElement {
  const [editing, setEditing] = useState(false);

  const [newPass, setNewPass] = useState({ oldpass: '', newpass: '', passconfirm: '' });
  const [visibleOldPass, setVisibleOldPass] = useState(false);
  const [visibleNewPass, setVisibleNewPass] = useState(false);
  const [visibleConfirmPass, setVisibleConfirmPass] = useState(false);

  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [updatedPassword, setUpdatedPassword] = useState(false);
  const [errorChangePassword, setErrorChangePassword] = useState(false);
  const [errorCurrentPassword, setErrorCurrentPassword] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setNewPass({ ...newPass, [name]: value });
  };

  const reset = () => {
    setEditing(false);
    setNewPass({ oldpass: '', newpass: '', passconfirm: '' });
  };

  const savePassword = (): void => {
    setUpdatingPassword(true);
    changePassword(newPass.oldpass, newPass.newpass).then(
      () => {
        setUpdatingPassword(false);
        setEditing(false);
        setUpdatedPassword(true);
        setTimeout(() => {
          setUpdatedPassword(false);
        }, 10000);
      },
      (err: string) => {
        setUpdatingPassword(false);
        if (err.indexOf('current password') > -1) {
          setErrorCurrentPassword(true);
          setTimeout(() => {
            setErrorCurrentPassword(false);
          }, 6000);
        } else {
          setErrorChangePassword(true);
          setTimeout(() => {
            setErrorChangePassword(false);
          }, 10000);
        }
      }
    );
  };

  return (
    <div className="row account-settings password-settings">
      <section>
        <label>Password</label>
        {!editing && <div className="value">***********</div>}
        {editing && (
          <div className="value">
            <div className="pass-container">
              <input
                className="legacy"
                value={newPass.oldpass}
                onChange={changeHandler}
                placeholder="Old Password"
                name="oldpass"
                required
                type={visibleOldPass ? 'text' : 'password'}
              />
              {newPass.oldpass && (
                <div className="show-password" onClick={() => setVisibleOldPass(!visibleOldPass)}>
                  <button
                    id={visibleOldPass ? 'hide-pass' : 'show-pass'}
                    className={visibleOldPass ? 'hide-pass' : 'show-pass'}
                    type="button"
                    tabIndex={-1}
                  ></button>
                </div>
              )}
            </div>
            <div className="pass-container">
              <input
                className="legacy"
                value={newPass.newpass}
                onChange={changeHandler}
                minLength={8}
                placeholder="New Password"
                name="newpass"
                required
                type={visibleNewPass ? 'text' : 'password'}
              />
              {newPass.newpass && (
                <div className="show-password" onClick={() => setVisibleNewPass(!visibleNewPass)}>
                  <button
                    id={visibleNewPass ? 'hide-pass' : 'show-pass'}
                    className={visibleNewPass ? 'hide-pass' : 'show-pass'}
                    type="button"
                    tabIndex={-1}
                  ></button>
                </div>
              )}
            </div>
            <div className="pass-container">
              <input
                className="legacy"
                value={newPass.passconfirm}
                onChange={changeHandler}
                minLength={8}
                placeholder="Confirm New Password"
                name="passconfirm"
                required
                type={visibleConfirmPass ? 'text' : 'password'}
              />
              {newPass.passconfirm && (
                <div className="show-password" onClick={() => setVisibleConfirmPass(!visibleConfirmPass)}>
                  <button
                    id={visibleConfirmPass ? 'hide-pass' : 'show-pass'}
                    className={visibleConfirmPass ? 'hide-pass' : 'show-pass'}
                    type="button"
                    tabIndex={-1}
                  ></button>
                </div>
              )}
              <p className="note">* New Password must be at least 8 characters</p>
            </div>
          </div>
        )}
        {!editing && (
          <div className="change-link">
            <a className="link" onClick={() => setEditing(true)} role="button">
              Change
            </a>
          </div>
        )}
      </section>
      {editing && (
        <div className="button-wrapper">
          <button className="btn secondary" onClick={reset}>
            Cancel
          </button>
          <button
            className={classnames('btn', { busy: updatingPassword })}
            disabled={updatingPassword || newPass.newpass !== newPass.passconfirm || newPass.newpass.length < 8}
            onClick={savePassword}
          >
            Save
          </button>
        </div>
      )}
      {updatedPassword && <div className="success password-updated">Password updated!</div>}
      {errorCurrentPassword && <div className="error password-updated">Current Password is invalid!</div>}
      {errorChangePassword && (
        <div className="error password-updated">Error while changing password, please retry later</div>
      )}
    </div>
  );
}
