import React, { useEffect, useState } from 'react';
import { differenceInYears, startOfToday } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { Input, Button, Password, TooltipTrigger, AvatarPicker, DatePicker } from '@arduino/arc';
import { IconOperationReloadArrow } from '@arduino/react-icons';
import { AvatarInfo } from '@arduino/arc/dist/components/avatar/AvatarPicker';

import { OptionalPassword } from './OptionalPassword';
import { LoadingSpinner } from '../LoadingSpinner';
import { getRandomUsername, getAvatars } from '../../api/children';
import { ChildPayload } from '../../_models/child';
import { ReactComponent as Logo } from '../../assets/logo.svg';

import InfoIcon from '../InfoIcon';

import './ChildForm.scss';

interface ChildFormProps {
  title: string;
  description: string;
  optionalPassword?: boolean;
  nextRoute: string;
  childData?: ChildPayload;
  currentAvatar?: AvatarInfo;
}

interface errorsInterface {
  password?: string;
  birthday?: string;
}

export function ChildForm({
  title,
  description,
  optionalPassword,
  childData,
  currentAvatar,
  nextRoute,
}: ChildFormProps) {
  const history = useHistory();

  const [avatarList, setAvatarList] = useState<AvatarInfo[] | undefined>();
  const [childInfo, setChildInfo] = useState(childData || ({} as ChildPayload));
  const [errors, setErrors] = useState({} as errorsInterface);

  const setField = (field: string, val: any) => {
    setChildInfo((prev) => ({ ...prev, [field]: val }));
  };

  const setRandomUsername = async () => {
    try {
      const newUsername = await getRandomUsername();
      setField('username', newUsername);
    } catch (e) {
      //setFetchError(e);
    }
  };

  const validateForm = () => {
    const errors = {
      password: childInfo.password || optionalPassword ? undefined : 'The password is required',
      birthday: childInfo.birthday ? undefined : 'The birthday is required',
    };
    if (!optionalPassword && !errors.password && childInfo.password && childInfo.password.length < 8) {
      errors.password = 'The password should be at least 8 characters long';
    }
    if (!errors.birthday && childInfo.birthday && differenceInYears(startOfToday(), childInfo.birthday) >= 14) {
      errors.birthday = 'The kid should be less than 14 years old';
    }
    setErrors(errors);
    return !Object.values(errors).some(Boolean);
  };

  const submitForm = async () => {
    if (!validateForm()) {
      return;
    }

    history.push(nextRoute, {
      ...childInfo,
      // if the avatar was already set by the child and not changed by the parent, don't send it
      avatar: childInfo.avatar !== currentAvatar?.name ? childInfo.avatar : undefined,
    });
  };

  useEffect(() => {
    (async () => {
      let avatars = await getAvatars();
      if (currentAvatar) {
        avatars = [currentAvatar, ...avatars];
      }

      if (!childInfo.username) {
        const username = await getRandomUsername();
        setField('username', username);
        setField('avatar', avatars[0].name);
      }
      setAvatarList(avatars);
    })();
  }, []);

  if (!childInfo.username || !avatarList) {
    return <LoadingSpinner />;
  }

  return (
    <section className="child-form">
      <div className="logo-wrapper">
        <Logo />
      </div>
      <h1 className="children-form-h1" dangerouslySetInnerHTML={{ __html: title }} />
      <p className="description">{description}</p>
      <div className="form">
        <div className="avatar">
          <AvatarPicker
            avatarSize={50}
            isEdit
            avatar={childInfo.avatar || ''}
            avatarList={avatarList}
            modalTitle={'Choose an avatar'}
            onSelect={(avatar) => setField('avatar', avatar)}
            className="avatar-picker"
          />
        </div>
        <div className="form-input username-input">
          <Input
            isReadOnly
            value={childInfo.username}
            label={"Kid's Username"}
            buttons={[<IconOperationReloadArrow key="icon" onClick={setRandomUsername} />]}
          />
          <div className="tooltip-row-wrapper">
            <TooltipTrigger
              TriggerElement={
                <div>
                  <InfoIcon />
                </div>
              }
              className="popover"
              tooltipPlacement="top-start"
              tooltipStrategy="absolute"
              tooltipType={'popover'}
            >
              <span>
                This username is anonymous and <strong>can not</strong> be used to identify your kid. It can be
                displayed publicly and used to log in to the account.
              </span>
            </TooltipTrigger>
          </div>
        </div>

        {optionalPassword ? (
          <div className="form-input">
            <OptionalPassword onSave={(password) => setField('password', password)} />
            <div className="tooltip-row-wrapper">
              <TooltipTrigger
                TriggerElement={
                  <div>
                    <InfoIcon />
                  </div>
                }
                className="popover"
                tooltipPlacement="top-start"
                tooltipStrategy="absolute"
                tooltipType={'popover'}
              >
                <span>The password was already set by your kid. You can set a new one before continuing.</span>
              </TooltipTrigger>
            </div>
          </div>
        ) : (
          <div className="form-input">
            <Password
              label="Password"
              error={errors.password}
              isRequired
              onChange={(e) => setField('password', e.target.value)}
            />
            <div className="tooltip-row-wrapper"></div>
          </div>
        )}

        <div className="form-input birthday">
          <DatePicker
            error={errors['birthday']}
            maxDate={new Date()}
            minDate={new Date(1920, 1, 1, 0, 0, 0)}
            isRequired
            label="Kid's Birthday"
            onChange={(date) => setField('birthday', date)}
            onError={(err) => setErrors({ ...errors, birthday: err! })}
            value={childInfo.birthday}
            isReadOnly={false}
          />
          <div className="tooltip-row-wrapper">
            <TooltipTrigger
              TriggerElement={
                <div>
                  <InfoIcon />
                </div>
              }
              className="popover"
              tooltipPlacement="top-start"
              tooltipStrategy="absolute"
              tooltipType={'popover'}
            >
              <span>
                We need your kid&apos;s birth date to display age-appropriate content and know when they can be
                transitioned to an adult account. This information <strong>will not</strong> be displayed publicly.
              </span>
            </TooltipTrigger>
          </div>
        </div>
        <div className="form-buttons">
          <Button type="submit" onPress={submitForm} className="next-btn">
            Next
          </Button>
        </div>
      </div>

      <div className="spacer"></div>
    </section>
  );
}
