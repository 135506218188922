import { Checkbox } from '@arduino/arc';
import classnames from 'classnames';
import React, { useState } from 'react';
import { AuthProfileProps } from './Profile';

type ProfileConsentProps = Pick<AuthProfileProps, 'consent' | 'saveConsent'>;

export function ProfileConsent({ consent, saveConsent }: ProfileConsentProps): React.ReactElement {
  const [saving, setSaving] = useState(false);
  const [updatedPrivacy, setUpdatedPrivacy] = useState(false);

  const [newConsent, setNewConsent] = useState({ ...consent });

  function showMessage() {
    setUpdatedPrivacy(true);
    setTimeout(() => {
      setUpdatedPrivacy(false);
    }, 3000);
  }

  function save() {
    setSaving(true);
    saveConsent(newConsent).then(() => {
      setSaving(false);
      showMessage();
    });
  }

  function changeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.checked;
    setNewConsent({ ...newConsent, [name]: value });
  }

  function reset() {
    setNewConsent({ newsletter: consent.newsletter, marketing: consent.marketing, tracking: consent.tracking });
  }

  return (
    <>
      <div className="section-title">Privacy Settings</div>
      <div className="section-content privacy-settings">
        <p className="intro">
          Arduino cares about the privacy and personal data of its users. This{' '}
          <a href="https://www.arduino.cc/en/Main/PrivacyPolicy" target="_blank" rel="noreferrer" className="link">
            privacy policy
          </a>{' '}
          is meant to inform you on how we process your personal data when you use our services.
        </p>

        <div className="list-checkbox">
          <Checkbox
            className="consentCheckbox"
            label="I confirm my consent to receive your newsletter"
            isSelected={newConsent.newsletter}
            onChange={changeHandler}
            name="newsletter"
          />
        </div>

        <div className="list-checkbox">
          <Checkbox
            className="consentCheckbox"
            label="I confirm my consent to the processing of my personal data for marketing purposes, consisting in commercial
            offers sent via email"
            isSelected={newConsent.marketing}
            onChange={changeHandler}
            name="marketing"
          />
        </div>

        <div className="list-checkbox">
          <Checkbox
            className="consentCheckbox"
            label="I confirm my consent to automated processing of my personal data, by means of profiling, in order to receive
            commercial offers customized on the basis of my browsing and purchasing behavior"
            isSelected={newConsent.tracking}
            onChange={changeHandler}
            name="tracking"
          />
        </div>

        {(newConsent.newsletter !== consent.newsletter ||
          newConsent.marketing !== consent.marketing ||
          newConsent.tracking !== consent.tracking) && (
          <div className="button-wrapper">
            <button className="btn secondary" onClick={reset}>
              Cancel
            </button>
            <button className={classnames('btn', { busy: saving })} onClick={save}>
              Save
            </button>
          </div>
        )}
        {updatedPrivacy && <div className="success">Your preferences have been correctly updated.</div>}
      </div>
    </>
  );
}
