import { Button } from '@arduino/arc';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { deleteChild } from '../api/children';
import { Child } from '../_models/child';
import './ProfileKids.scss';

export interface ProfileKidDeleteProps {
  kid: Child;
  setIsOpen: (open: boolean) => void;
  onDeleteKid: (id: number) => void;
}
export function ProfileKidDelete({ kid, setIsOpen, onDeleteKid }: ProfileKidDeleteProps): React.ReactElement {
  const history = useHistory();
  const [error, seterror] = useState('');

  const deleteKid = () => {
    // clear any previous error
    seterror('');

    deleteChild(kid.id.toString())
      .then(() => {
        // show snackbar and close the modal
        history.replace('/', { snackbarMessage: `The kid account was successfully deleted.` });
        onDeleteKid(kid.id);
        setIsOpen(false);
      })
      .catch((err) => {
        seterror(err);
      });
  };

  return (
    <div className="kidsmodal">
      <div className="kidsmodal--content">
        Are you sure you want to delete <strong>{kid.username}</strong>?
        <br /> This action is permanent and cannot be undone.
        <p>
          {
            'Once you delete the account, the products ownership will be lost. If you wish to keep the ownership, please '
          }
          <a className="link" href="//arduino.cc/en/contact-us" target="_blank" rel="noopener noreferrer">
            contact us
          </a>
          {' before deleting the account and request a transfer to your account.'}
        </p>
      </div>
      {!!error && <div className="kidsmodal--error">{error}</div>}
      <div className="kidsmodal--actions">
        <Button variant="tertiary" onPress={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button variant="warning" onPress={deleteKid}>
          Yes, Delete
        </Button>
      </div>
    </div>
  );
}
