export interface User {
  id?: number;
  username?: string;
  email?: string;
  private?: PrivateData;
  public?: PublicData;
  social?: Social;
  telephone?: string;
  billing?: Billing;
  addresses?: Address[];
  auth?: Auth;
  contact?: Contact;
  external_login?: ExternalLogin;
}

export interface PrivateData {
  birth_date: string;
  gender: string;
  location: string;
  name: string;
}

export interface PublicData {
  avatar: string;
  avatar_data: string;
  bio: string;
  website: string;
  [key: string]: string;
}

export interface Social {
  facebook: string;
  github: string;
  twitter: string;
  temboo: string;
  [key: string]: string;
}

export interface Billing {
  type: string;
  name: string;
  surname: string;
  address1: string;
  city: string;
  state: string;
  country: string;
}

export interface Address {
  type: string;
  name: string;
  surname: string;
  address1: string;
  city: string;
  state: string;
  country: string;
}

export interface Auth {
  current_password: string;
  new_password: string;
  new_password_confirm: string;
}

export interface ExternalLogin {
  provider: string;
  primary_method: boolean;
}

export interface Contact {
  email: string;
}

export interface ConsentInfo {
  marketing?: boolean;
  newsletter?: boolean;
  privacy?: boolean;
  privacy_timestamp?: Date;
  terms_and_conditions?: boolean;
  terms_and_conditions_timestamp?: Date;
  tracking?: boolean;
}

export enum ActionType {
  ACTIVATE_USER = 'activate_user',
  CHANGE_EMAIL = 'change_email',
  DELETE_USER = 'delete_user',
  DISABLE_MFA = 'disable_mfa',
  LINK_CHILD = 'link_child',
  CONFIRM_TEEN = 'confirm_teen',
}
