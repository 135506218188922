import React, { useState, useEffect, useRef } from 'react';
import { IconNavigationCloseNormal, IconNavigationCheckmarkOutlineFilled } from '@arduino/react-icons';
import './AlertMessage.scss';

interface AlertMessageProps {
  children: React.ReactNode;
  visible?: boolean;
}

export function AlertMessage({ children, visible }: AlertMessageProps) {
  const [isVisible, setVisible] = useState(false);
  const timerId = useRef<any>(null);

  const close = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(!!visible);
    if (visible) {
      timerId.current = setTimeout(() => {
        close();
      }, 7000);
    }
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
        timerId.current = null;
      }
    };
  }, [visible]);

  if (!isVisible) {
    return <></>;
  }
  return (
    <div className="alert-message">
      <div className="message-wrapper">
        <IconNavigationCheckmarkOutlineFilled />
        <div className="message">{children}</div>
      </div>
      <span role="button" tabIndex={1} className="close-button" onClick={close}>
        <IconNavigationCloseNormal />
      </span>
    </div>
  );
}
