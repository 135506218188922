import React, { useEffect, useState } from 'react';

/**
 * The loading spinner happears after 200msec.
 * This is because from a UX point of view every action that takes less than 150ms is considered immediate by the human brain
 * We want to display the loading only if it actually takes more than 200 msec, as that will effectively be perceived as a "delay"
 */
export function LoadingSpinner(): React.ReactElement | null {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setShow(true);
    }, 200);

    return () => {
      clearTimeout(showTimeout);
    };
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className="spinner-container">
      <div className="spinner">Loading...</div>
    </div>
  );
}
