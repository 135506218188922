import React from 'react';
import { IconNavigationArrowLeft } from '@arduino/react-icons';
import PropTypes from 'prop-types';

import './ChildHeader.scss';

interface ChildHeaderProps {
  hideBackButton?: boolean;
  onBack: () => void;
}

const ChildHeader = ({ onBack, hideBackButton }: ChildHeaderProps) => {
  return (
    <div className="header-container">
      {!hideBackButton && (
        <button className="icon-button" onClick={onBack} aria-label="Go back">
          <IconNavigationArrowLeft />
        </button>
      )}
    </div>
  );
};

ChildHeader.propTypes = {
  onBack: PropTypes.func,
};

export default ChildHeader;
