import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import { confirmChild, saveChild } from '../../api/children';
import { executeAction } from '../../api/actions';
import { ChildPayload } from '../../_models/child';

import { Checkbox, Button } from '@arduino/arc';

import './ChildConsent.scss';
import { ActionType } from '../../_models/user';

type ChildConsentProps = {
  isCoppa: boolean;
};

export function ChildConsent(props: ChildConsentProps) {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const location = useLocation<ChildPayload>();
  const childPayload = location.state;

  // kick away users getting to this page via direct url or hard reloads
  useEffect(() => {
    if (!childPayload && props.isCoppa) {
      history.replace('/');
      return;
    }
  }, []);

  const [checked, setChecked] = useState({
    privacy: false,
    arduino: false,
    consent: false,
  });

  const [isLoading, setLoading] = useState(false);

  const set = (which: string, val: boolean) => {
    setChecked((prev) => ({ ...prev, [which]: val }));
  };

  const onSubmit = () => {
    if (isLoading) {
      return;
    }
    let action;
    setLoading(true);
    if (props.isCoppa) {
      action = id ? confirmChild(id, childPayload) : saveChild(childPayload);
    } else {
      action = executeAction(id, ActionType.CONFIRM_TEEN);
    }
    action.then(() =>
      history.push('/', { message: 'Your child’s account was successfully created. They can now sign in.' })
    );
  };

  return (
    <section className="child-consent">
      <h1 className="children-form-h1">Parental consent</h1>
      <div className="form">
        <div className="preamble">
          <p>
            For a child under 16 to have an Arduino Account, an adult with parental rights needs to consent its
            creation. Our Privacy Policy provides important information that you should consider when deciding whether
            to allow kids to sign up to Arduino.
          </p>
          {props.isCoppa ? (
            <p>
              {
                "Once you grant permission, your child's account will be bound to your email address, and added to the Junior Accounts section in your profile where you’ll be able to manage it. All the content accessible with a Junior account is tailored for the kids."
              }
            </p>
          ) : (
            <p>
              {
                'Once you grant permission, your child’s account will be able to navigate the Arduino ecosystem as a regular account.'
              }
            </p>
          )}
          In order to continue, please agree with the following:
        </div>
        <div className="approvals">
          <Checkbox
            label="I have read the [Privacy Policy](https://www.arduino.cc/PrivacyPolicy/) and accept the [Terms of Service](https://www.arduino.cc/en/Main/TermsOfService)."
            isRequired={true}
            onChange={(e) => set('privacy', e.target.checked)}
          />
          <Checkbox
            label={`I agree to let Arduino create a ${
              props.isCoppa ? 'Junior' : 'regular'
            } account for my child as described above, and further detailed in the [Privacy Policy](https://www.arduino.cc/PrivacyPolicy/).`}
            isRequired={true}
            onChange={(e) => set('arduino', e.target.checked)}
          />
          <Checkbox
            label="I agree with the terms of parental consent and I am authorised to provide this consent."
            isRequired={true}
            onChange={(e) => set('consent', e.target.checked)}
          />
        </div>
        <div className="form-buttons">
          <Button
            type="submit"
            onPress={onSubmit}
            isDisabled={!Object.values(checked).every(Boolean)}
            loading={isLoading}
          >
            Create account
          </Button>
        </div>
      </div>
    </section>
  );
}
