import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Button, Snackbar } from '@arduino/arc';
import { differenceInYears, startOfToday } from 'date-fns';
import Tippy from '@tippyjs/react/headless';

import { AuthContextType } from '../context/auth';
import { ProfileHeader } from './ProfileHeader';
import { ProfileSettingCard } from './ProfileSettingCard';
import { bookAction } from '../api/actions';
import { ProfileAccount } from './ProfileAccount';
import { ProfileSecurity } from './ProfileSecurity';
import { ProfileConsent } from './ProfileConsent';
import { ProfileKids } from './ProfileKids';
import { ConsentInfo, User } from '../_models/user';
import { changePassword } from '../api/profile';
import { Child } from '../_models/child';

export interface AuthProfileProps {
  profile: User;
  saveProfile: (profile: User, scopes?: string[]) => Promise<boolean>;
  consent: ConsentInfo;
  kids: Child[];
  changePassword: typeof changePassword;
  resetPassword: () => Promise<AxiosResponse<any>>;
  saveConsent: (consent: ConsentInfo) => Promise<any>;
  onDeleteKid: (kidId: number) => void;
  authContext: AuthContextType;
  bookAction: typeof bookAction;
}

const _Profile = ({
  profile,
  saveProfile,
  consent,
  kids,
  changePassword,
  authContext,
  bookAction,
  resetPassword,
  saveConsent,
  onDeleteKid,
}: AuthProfileProps): React.ReactElement => {
  const { state } = useLocation();
  const [popUpMessage, setPopUpMessage] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  useEffect(() => {
    setPopUpMessage(state && (state as any).message ? (state as any).message : null);
    setSnackbarMessage(state && (state as any).snackbarMessage ? (state as any).snackbarMessage : null);
  }, [state]);

  // Teenagers can't manage part of their profile (like the consent and the kids section)
  const isTeen = authContext.birthday && differenceInYears(startOfToday(), new Date(authContext.birthday)) < 16;

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  return (
    <div className="profile-page">
      <ProfileHeader profile={profile} saveProfile={saveProfile} authContext={authContext} />

      <div className="row">
        <div className="column">
          <ProfileSettingCard>
            <ProfileAccount
              profile={profile}
              changePassword={changePassword}
              authContext={authContext}
              bookAction={bookAction}
              resetPassword={resetPassword}
            />
          </ProfileSettingCard>
          {!authContext.eduConnection && authContext.emailVerified && (
            <>
              <ProfileSettingCard>
                <ProfileSecurity authContext={authContext} bookAction={bookAction} />
              </ProfileSettingCard>
              {!isTeen && (
                <ProfileSettingCard>
                  <ProfileConsent consent={consent} saveConsent={saveConsent} />
                </ProfileSettingCard>
              )}
            </>
          )}
        </div>

        <div className="column">
          <div className="forum-settings box">
            <div className="section-title">
              Forum Settings
              <span
                className="link forum"
                onClick={() => {
                  window.location.href = 'https://forum.arduino.cc/index.php?action=profile';
                }}
              >
                Edit
              </span>
            </div>
          </div>
          {!isTeen && (
            <>
              <div ref={ref}>
                <ProfileSettingCard>
                  <ProfileKids kids={kids} onDeleteKid={onDeleteKid} />
                </ProfileSettingCard>
              </div>
              <Tippy
                visible={!!popUpMessage}
                reference={ref}
                interactive={true}
                placement={'right-start'}
                zIndex={2}
                popperOptions={{
                  modifiers: [
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['left-start', 'top'],
                      },
                    },
                  ],
                }}
                render={(attrs) => (
                  <div className="child-created-tooltip" {...attrs}>
                    <p>
                      Your child’s account was successfully created, they can now sign in with their credentials. Here
                      you can reset their password or deleting the account.
                    </p>
                    <div className="tooltip-arrow"></div>
                    <Button size="small" type="button" onPress={() => setPopUpMessage(null)} className="next-btn">
                      okay
                    </Button>
                  </div>
                )}
              ></Tippy>
            </>
          )}
          <Snackbar
            message={snackbarMessage}
            isOpen={!!snackbarMessage}
            closeable
            onClose={() => setSnackbarMessage(null)}
          ></Snackbar>
        </div>
      </div>
    </div>
  );
};

export const Profile = React.memo(_Profile);
