import React from 'react';

import failImg from '../assets/fail.svg';

export function AccountDeleted(): React.ReactElement {
  return (
    <div className="profile-page account-deleted">
      <img src={failImg} />
      <h2>Your Arduino Account has been successfully deleted.</h2>
      <p>
        You can{' '}
        <a href="https://auth.arduino.cc/register" className="link">
          Sign Up
        </a>{' '}
        again anytime
      </p>
    </div>
  );
}
