import { axiosClient } from '../context/auth';
import { User } from '../_models/user';

export async function getProfile() {
  const query = process.env
    .REACT_APP_SCOPES!.split(',')
    .map((scope: string) => `scopes=${scope}`)
    .join('&');
  return axiosClient.get<User>(`${process.env.REACT_APP_API_URL}/v1/users/byID/me?${query}`);
}

export async function resetPassword(profile: User) {
  return axiosClient.post(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`, {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    email: profile.contact ? profile.contact.email : profile.email,
    connection: 'arduino',
  });
}

export async function changePassword(currentPassword: string, newPassword: string) {
  return axiosClient.post(`${process.env.REACT_APP_API_URL}/v1/users/byID/me/change_pass`, {
    current_password: currentPassword,
    new_password: newPassword,
  });
}

export async function saveProfile(profile: User, scopes: string[] = []) {
  if (scopes.length === 0) {
    scopes = process.env.REACT_APP_SCOPES!.split(',');
  }

  return Promise.all(
    scopes.map((scope: string) =>
      axiosClient.put(`${process.env.REACT_APP_API_URL}/v1/users/byID/me/${scope}`, (profile as any)[scope])
    )
  ).then((responses: any) => {
    const result = scopes.reduce((result: any, scope: string, index) => {
      result[scope] = responses[index].data;
      return result;
    }, {});
    return result;
  });
}
