import { Button } from '@arduino/arc';
import { IconNavigationAddPlusNormal, IconFileFileCopy } from '@arduino/react-icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Child } from '../_models/child';
import { ProfileKidRow } from './ProfileKidRow';
import './ProfileKids.scss';

export interface ProfileKidsProps {
  kids: Child[];
  onDeleteKid: (kidId: number) => void;
}

export function ProfileKids({ kids, onDeleteKid }: ProfileKidsProps): React.ReactElement {
  const history = useHistory();
  const [isCopied, setIsCopied] = useState(false);

  const onCopy = (value: string) => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  const [popoversState, setPopoversState] = useState<Record<string, boolean>>(
    kids.reduce((prev, curr) => ({ ...prev, [curr.username]: false }), {})
  );

  // as soon as we receive a open popover, we close all the others
  const setPopoverOpen = (username: string, state: boolean) => {
    const allClosed: Record<string, boolean> = Object.keys(popoversState).reduce(
      (prev, curr) => ({ ...prev, [curr]: false }),
      {}
    );
    // override the changed one with the new state
    const newState = { ...allClosed, [username]: state };
    setPopoversState(newState);
  };

  return (
    <>
      <div className="section-title">Junior accounts</div>
      <div className="section-content kids-section">
        {kids.length == 0 && (
          <p className="intro">
            You can create a junior account connected to yours. It provides a safe environment by restricting access to
            community-generated content, social features and purchases.
          </p>
        )}
        {kids.length > 0 && (
          <>
            <p className="intro">These are the junior accounts associated with your profile.</p>
            <ul className="kidsList">
              {kids.map((kid) => (
                <li key={kid.id}>
                  <ProfileKidRow
                    kid={kid}
                    popoverOpen={popoversState[kid.username]}
                    setPopoverOpen={setPopoverOpen}
                    onDeleteKid={onDeleteKid}
                  />
                </li>
              ))}
            </ul>
          </>
        )}

        <Button variant="tertiary" onPress={() => history.push('/kids/new')}>
          <>
            <IconNavigationAddPlusNormal className="addKidIcon" />
            {kids.length === 0 && 'SET UP JUNIOR ACCOUNT'}
            {kids.length > 0 && 'ADD ANOTHER'}
          </>
        </Button>

        {kids.length > 0 && (
          <div className="minor-login-box">
            <p>Please share this link with your Child to login to use Arduino Cloud services:</p>
            <div className="minor-login-box__link">
              <span>app.arduino.cc/minors</span> <IconFileFileCopy onClick={() => onCopy('app.arduino.cc/minors')} />
              {isCopied && <div className="minor-login-box__success">Copied to clipboard!</div>}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
