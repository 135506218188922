import React, { useState } from 'react';

import classnames from 'classnames';
import { AuthProfileProps } from './Profile';

type ProfileAvatarProps = Pick<AuthProfileProps, 'profile' | 'saveProfile' | 'authContext'>;
export function ProfileAvatar({ profile, saveProfile, authContext }: ProfileAvatarProps): React.ReactElement {
  const [uploadedAvatar, setUploadedAvatar] = useState('');
  const [uploadMode, setUploadMode] = useState(false);
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);

  function resetUpload(): void {
    setUploadMode(false);
    setUploadedAvatar('');
    setError('');
  }

  function saveAvatar(): void {
    setSaving(true);
    setError('');

    // copy the most recent profile and set the avatar_data just before sending it to backend.
    // this avoid the avatar save to overwrite other saves
    const profileCopy = JSON.parse(JSON.stringify(profile));
    profileCopy.public.avatar_data = uploadedAvatar.split(',')[1];

    saveProfile(profileCopy, ['public'])
      .then(() => {
        setUploadMode(false);
        setUploadedAvatar('');
        setError('');
      })
      .catch(() => {
        setError('Failed to update avatar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function _handleImageChange(e: any): void {
    e.preventDefault();
    setError('');

    const reader = new FileReader();
    const file = e.target.files[0];

    // check file size - 500KB
    if (file.size > 500000) {
      setError(`File is too big, size ${file.size}`);
      return;
    }

    reader.onloadend = () => {
      const dataUrl = reader.result as string;
      setUploadedAvatar(dataUrl);
    };

    reader.onerror = () => {
      setError(`Failed to upload avatar file: ${reader.error}`);
      reader.abort();
    };
    reader.readAsDataURL(file);
  }

  const fallbackImg = 'https://content.arduino.cc/avatars/default.svg';
  function imgFallback(ev: any) {
    ev.target.src = fallbackImg;
  }

  return (
    <>
      {!uploadMode && (
        <div className="img-container" onClick={() => setUploadMode(true)}>
          <div className="img-wrapper">
            <img
              src={profile?.public?.avatar || authContext.picture || fallbackImg}
              onError={imgFallback}
              alt="Personal Avatar"
              className={classnames({ 'custom-avatar': profile?.public?.avatar })}
            />
          </div>
          <div className="upload-link link">Change Profile Pic</div>
        </div>
      )}
      {uploadMode && (
        <div className="upload-avatar-form">
          <div className="upload-pic">
            {!uploadedAvatar && (
              <label>
                <input
                  className="legacy"
                  type="file"
                  name="userfile"
                  required
                  accept=".jpg,.png,.gif"
                  onChange={_handleImageChange}
                />
                <span>Upload Image</span>
              </label>
            )}
            {uploadedAvatar && (
              <div className="preview-upload">
                <img src={uploadedAvatar} alt="New Avatar" />
              </div>
            )}
          </div>
          <div className="profile-pic-instructions">
            <p>
              Allowed image types are <strong>jpg</strong>,<strong>png</strong> and <strong>gif</strong>.
            </p>
            <p>
              Uploaded image must be less than <strong>500KB</strong>.
            </p>
            <p>
              The uploaded image is automatically resized to fit into <strong>300x300</strong> pixels, using a square
              picture is recommended to avoid stretching.
            </p>
          </div>
          {error.length > 0 && <div className="error">{error}</div>}
          <div className="upload-avatar-buttons">
            <button className="btn secondary" onClick={resetUpload}>
              Cancel
            </button>
            <button className={classnames('btn', { busy: saving })} disabled={saving} onClick={saveAvatar}>
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
}
