import React, { useEffect, useState } from 'react';
import { Input, Button, Password, Modal, TooltipTrigger } from '@arduino/arc';
import { ConvertToTeenPayload } from '../../_models/child';
import { convertChildToAdult } from '../../api/children';
import { IconNavigationCloseOutlineFilled } from '@arduino/react-icons';
import { useHistory } from 'react-router-dom';
import InfoIcon from '../InfoIcon';

import './ConvertTeenToAdult.scss';
import { ChildLayout } from './ChildLayout';
import { useAuthContext } from '../../context/auth';
import { differenceInYears, startOfToday } from 'date-fns';

interface errorsInterface {
  username?: string;
  password?: string;
  email?: string;
  parent_email?: string;
}

export function ConvertTeenToAdult() {
  const [teenInfo, setTeenInfo] = useState<ConvertToTeenPayload>({
    username: '',
    password: '',
    email: '',
    parent_email: '',
  });
  const [errors, setErrors] = useState({} as errorsInterface);
  const [serverError, setServerErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const history = useHistory();
  const authContext = useAuthContext();

  useEffect(() => {
    if (!authContext.authenticated) {
      return;
    }
    // Check if is an eligible user (> 14 and isMinor)
    if (!authContext.isMinor || differenceInYears(startOfToday(), new Date(authContext.birthday)) < 14) {
      history.push('/error');
    }
  }, [authContext.authenticated, authContext.isMinor, authContext.birthday]);

  const setField = (field: string, val: any) => {
    setTeenInfo((prev) => ({ ...prev, [field]: val }));
  };

  const validateForm = () => {
    const re =
      /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

    const errors = {
      password: teenInfo.password ? undefined : 'The password is required',
      username: teenInfo.username ? undefined : 'The username is required',
      email: teenInfo.email ? undefined : 'The email is required',
      parent_email: teenInfo.parent_email ? undefined : "The parent's email is required",
    };
    if (teenInfo.password && teenInfo.password?.length < 8) {
      errors.password = 'Minimum 8 characters';
    }

    if (teenInfo.username && teenInfo.username?.length < 3) {
      errors.password = 'Minimum 3 characters';
    }

    if (teenInfo.email && !re.test(String(teenInfo.email).toLowerCase())) {
      errors.email = 'Must be a valid email';
    }

    if (teenInfo.parent_email && !re.test(String(teenInfo.parent_email).toLowerCase())) {
      errors.email = 'Must be a valid email';
    }

    setErrors(errors);
    return !Object.values(errors).some(Boolean);
  };

  const submitForm = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      await convertChildToAdult(teenInfo);
      setShowConfirmModal(true);
    } catch (e: any) {
      setServerErrors(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ChildLayout hideBackButton>
      <section className="convert-form">
        <h1 className="children-form-h1">{'Convert to a regular account'}</h1>
        <p className="description">{'You can now upgrade to a regular account.\n Update the information below.'}</p>
        <div className="form">
          <div className="form-input">
            <Input
              name="username"
              label={'New Username'}
              error={errors['username']}
              isRequired
              onChange={(e) => setField('username', e.target.value)}
            />
          </div>

          <div className="form-input">
            <Password
              label="New Password"
              error={errors['password']}
              isRequired
              onChange={(e) => setField('password', e.target.value)}
            />
          </div>

          <div className="form-input">
            <Input
              name="email"
              label={'Your own email address '}
              error={errors['email']}
              isRequired
              onChange={(e) => setField('email', e.target.value)}
            />
          </div>

          <div className="form-input">
            <Input
              name="email"
              label={"Your parent's email address "}
              error={errors['parent_email']}
              isRequired
              onChange={(e) => setField('parent_email', e.target.value)}
            />
            <div className="tooltip-row-wrapper">
              <TooltipTrigger
                TriggerElement={
                  <div>
                    <InfoIcon />
                  </div>
                }
                className="popover"
                tooltipPlacement="top-start"
                tooltipStrategy="absolute"
                tooltipType={'popover'}
              >
                <span>
                  <strong>A parent still needs to give conset to convert your account.</strong>
                  <br />
                  Please enter their email address and we will send instructions on how to proceed.
                </span>
              </TooltipTrigger>
            </div>
          </div>

          {serverError ? (
            <div className="alert-box">
              <IconNavigationCloseOutlineFilled />
              <span>{serverError}</span>
            </div>
          ) : null}
          <div className="form-buttons">
            <Button type="submit" onPress={submitForm} className="next-btn" loading={loading}>
              Next
            </Button>
          </div>
        </div>

        <Modal
          isOpen={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          isDismissable={false}
          title={''}
          primaryButton={{
            label: 'Got it',
            closeModalOnClick: false,
            onClick: () => {
              setShowConfirmModal(false);
              window.auth.logout();
            },
          }}
        >
          <>
            <h4 className="arduino-typography-x-medium-strong">{'Ask yout parent for help'}</h4>
            <div className="arduino-typography-x-small">
              {'We sent an email with instructions to your parent’s email '}
              <strong>{teenInfo.parent_email}</strong>
              {'. You will be able to sign in once they finalize your account.'}
            </div>
          </>
        </Modal>

        <div className="spacer"></div>
      </section>
    </ChildLayout>
  );
}
