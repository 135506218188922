import React, { useState } from 'react';
import { Button, Input } from '@arduino/arc';
import { IconNavigationCloseOutlineFilled } from '@arduino/react-icons';

type SendParentEmailFormProps = {
  onSubmit: (parentEmail: string) => void;
  error: string;
  loading: boolean;
  invalidEmail: string;
};

interface errorsInterface {
  parent_email?: string;
  confirm_email?: string;
}

export function SendParentEmailForm(props: SendParentEmailFormProps) {
  const { onSubmit, error, loading, invalidEmail } = props;

  const [parentEmail, setParentEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [errors, setErrors] = useState({} as errorsInterface);

  const validateForm = () => {
    const re =
      /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

    const errors = {
      parent_email: parentEmail ? undefined : 'The parent email is required',
      confirm_email: confirmEmail ? undefined : 'The confirm email is required',
    };

    if (parentEmail && !re.test(String(parentEmail).toLowerCase())) {
      errors.parent_email = 'Must be a valid email';
    }

    if (parentEmail && parentEmail === invalidEmail) {
      errors.parent_email = 'Must be different from your email';
    }

    if (parentEmail && parentEmail !== confirmEmail) {
      errors.confirm_email = 'Parent email and confirmation do not match';
    }

    setErrors(errors);
    return !Object.values(errors).some(Boolean);
  };

  const submitForm = async () => {
    if (!validateForm()) {
      return;
    }

    onSubmit(parentEmail);
  };

  return (
    <section className="send-parent-email">
      <h1 className="children-form-h1">Parent email address</h1>
      <p className="description">
        {
          'A parent needs to give conset to create your account. Please enter their email address below and we will send instructions on how to proceed.'
        }
      </p>
      <div className="form">
        <div className="form-input">
          <Input
            name="email"
            label={'Parent’s email address'}
            error={errors['parent_email']}
            isRequired
            onChange={(e) => setParentEmail(e.target.value)}
          />
        </div>
        <div className="form-input">
          <Input
            name="confirm_email"
            label={'Confirm email address'}
            error={errors['confirm_email']}
            isRequired
            onChange={(e) => setConfirmEmail(e.target.value)}
          />
        </div>
        {error ? (
          <div className="alert-box">
            <IconNavigationCloseOutlineFilled />
            <span>{error}</span>
          </div>
        ) : null}
        <div className="form-buttons">
          <Button type="submit" onPress={submitForm} className="next-btn" loading={loading}>
            Send email
          </Button>
        </div>
      </div>
    </section>
  );
}
