import React, { useState, useEffect, useCallback } from 'react';
import { changePassword, getProfile, resetPassword, saveProfile } from '../api/profile';
import { useHistory } from 'react-router-dom';
import { bookAction } from '../api/actions';
import { getConsent, saveConsent } from '../api/consent';
import { Profile } from '../components/Profile';
import { AuthContextType, useAuthContext } from '../context/auth';

import { ConsentInfo, User } from '../_models/user';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { getChildren } from '../api/children';
import { Child } from '../_models/child';

export function ProfileContainer(): React.ReactElement {
  const [profile, setProfile] = useState<User | null>(null);
  const [consent, setConsent] = useState<ConsentInfo | null>(null);
  const [kids, setKids] = useState<Child[]>([]);
  const history = useHistory();

  const authContext: AuthContextType = useAuthContext();

  const onDeleteKid = useCallback(
    (kidId) => {
      const filteredKids = kids.filter((kid) => kid.id !== kidId);
      setKids(filteredKids);
    },
    [kids]
  );

  useEffect(() => {
    if (!authContext.authenticated) {
      return;
    }

    // Broken user try to access profile should first be converted
    if (authContext.eduConnection) {
      history.replace(`/kids/convert-broken`);
      return;
    }

    Promise.all([getProfile(), getConsent(), getChildren()])
      .then(([profileValue, consent, kids]) => {
        setKids(kids);
        setProfile(profileValue.data);
        setConsent(consent.data);
      })
      .catch((error) => {
        console.error(error.message);
        history.replace(`/error`);
      });
  }, [authContext.authenticated]);

  const profileSave = useCallback(
    (updReq: User, scopes?: string[]): Promise<boolean> => {
      return saveProfile(updReq, scopes).then((updRes) => {
        const newProfile = {
          ...profile,
          ...updRes,
        };

        setProfile(newProfile);

        return true;
      });
    },
    [profile]
  );

  const consentSave = useCallback((consentReq: ConsentInfo) => {
    return saveConsent(consentReq).then((consentRes) => {
      setConsent(consentRes as ConsentInfo);
    });
  }, []);

  const resetPass = useCallback(() => {
    return resetPassword(profile || {});
  }, [profile]);

  if (!profile || !consent || !authContext.authenticated) {
    return <LoadingSpinner />;
  }

  return (
    <Profile
      profile={profile}
      consent={consent}
      kids={kids}
      authContext={authContext}
      saveProfile={profileSave}
      saveConsent={consentSave}
      changePassword={changePassword}
      resetPassword={resetPass}
      bookAction={bookAction}
      onDeleteKid={onDeleteKid}
    />
  );
}
