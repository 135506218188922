import React, { useState } from 'react';
import { Button, TooltipTrigger, DatePicker } from '@arduino/arc';
import { useHistory } from 'react-router-dom';
import InfoIcon from '../../InfoIcon';

import './AgeGate.scss';
import { ChildLayout } from '../ChildLayout';
import { differenceInYears, startOfToday, format } from 'date-fns';

interface errorsInterface {
  password?: string;
  birthday?: string;
}

export function AgeGate() {
  const [birthday, setBirthday] = useState<Date | undefined>(undefined);
  const history = useHistory();
  const [errors, setErrors] = useState({} as errorsInterface);

  const validateForm = () => {
    const errors = {
      birthday: birthday ? undefined : 'The birthday is required',
    };

    setErrors(errors);
    return !Object.values(errors).some(Boolean);
  };

  const submit = () => {
    if (!validateForm()) {
      return;
    }

    if (birthday) {
      if (differenceInYears(startOfToday(), birthday) >= 14) {
        history.push(`/kids/convert-broken/regular/${format(birthday, 'yyyy-MM-dd')}`);
      } else {
        history.push(`/kids/convert-broken/coppa/${format(birthday, 'yyyy-MM-dd')}`);
      }
    }
  };

  return (
    <ChildLayout hideBackButton>
      <section className="agegate">
        <h1 className="children-form-h1">{'Update your account information'}</h1>

        <p className="description">{'Enter your birth date to continue.'}</p>

        <div className="form">
          <div className="form-input birthday">
            <DatePicker
              error={errors['birthday']}
              maxDate={new Date()}
              minDate={new Date(1920, 1, 1, 0, 0, 0)}
              isRequired
              label="Birthday"
              onChange={(date) => setBirthday(date)}
              onError={(err) => setErrors({ ...errors, birthday: err! })}
              value={birthday}
              isReadOnly={false}
            />
            <div className="tooltip-row-wrapper">
              <TooltipTrigger
                TriggerElement={
                  <div>
                    <InfoIcon />
                  </div>
                }
                className="popover"
                tooltipPlacement="top-start"
                tooltipStrategy="absolute"
                tooltipType={'popover'}
              >
                <span>
                  We need to know your birth date to assign you the right account type. This information will not be
                  displayed pubblicly.
                </span>
              </TooltipTrigger>
            </div>
          </div>

          <div className="form-buttons">
            <Button type="submit" onPress={submit}>
              {'Next'}
            </Button>
          </div>
        </div>

        <div className="spacer"></div>
      </section>
    </ChildLayout>
  );
}
