import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { OverlayProvider } from 'react-aria';
import { Authentication } from './Authentication';
import { initHeaderAndFooter } from './HeaderAndFooter';
import { ProfileContainer } from './containers/ProfileContainer';
import { ExecuteAction } from './components/ExecuteAction';
import { AccountDeleted } from './components/AccountDeleted';
import { Children } from './components/children/Children';
import { Error } from './components/Error';

import './App.scss';

function App(): React.ReactElement {
  const location = useLocation();

  useEffect(() => {
    const headerStyle = location.pathname.startsWith('/kids') ? 'platform' : 'arduino';
    initHeaderAndFooter(headerStyle, location.pathname.startsWith('/kids/convert-broken'));
  }, [location]);

  return (
    <OverlayProvider>
      <Switch>
        <Route path="/error">
          <Error />
        </Route>
        <Route path="*">
          <Authentication>
            <Switch>
              <Route path="/kids/*">
                <Children />
              </Route>
              <Route path="*">
                <Switch>
                  <Route path="/actions/:action/:token">
                    <ExecuteAction />
                  </Route>
                  <Route path="/accountdeleted">
                    <AccountDeleted />
                  </Route>
                  <Route exact path="/">
                    <ProfileContainer />
                  </Route>
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </Route>
            </Switch>
          </Authentication>
        </Route>
      </Switch>
    </OverlayProvider>
  );
}

export default App;
