import React, { useState } from 'react';
import { ActionType } from '../_models/user';
import { AuthProfileProps } from './Profile';

import './ProfileAccount.scss';
import { ProfileEmail } from './ProfileEmail';
import { ProfilePassword } from './ProfilePassword';
import { ProfilePasswordReset } from './ProfilePasswordReset';

type ProfileAccountProps = Pick<
  AuthProfileProps,
  'profile' | 'authContext' | 'bookAction' | 'changePassword' | 'resetPassword'
>;

export function ProfileAccount({
  profile,
  authContext,
  bookAction,
  changePassword,
  resetPassword,
}: ProfileAccountProps): React.ReactElement {
  const [confirmDeletion, setConfirmDeletion] = useState(false);

  function deleteProfile(): void {
    setConfirmDeletion(true);
    bookAction({}, ActionType.DELETE_USER, true);
  }

  return (
    <>
      <div className="section-title">Account Settings</div>
      {authContext.googleSignIn && (
        <div className="google-signin">
          <span className="google-icon"></span>
          <span className="label">
            <span>Logged in</span>
            <span>with Google</span>
          </span>
        </div>
      )}
      <div className="section-content">
        <ProfileEmail profile={profile} authContext={authContext} bookAction={bookAction} />
        {authContext.hasPassword && <ProfilePassword changePassword={changePassword} />}
        {!authContext.hasPassword && <ProfilePasswordReset resetPassword={resetPassword} />}
      </div>
      <div className="delete-account">
        {confirmDeletion && (
          <div className="success">
            You will shortly receive a confirmation email, click the link in the email to confirm your account deletion.
          </div>
        )}
        {!confirmDeletion && (
          <>
            <div>
              If you wish to delete your Arduino account{' '}
              <a className="link" onClick={deleteProfile}>
                click here
              </a>
              .
            </div>
            <div>A confirmation link will be sent to your email.</div>
          </>
        )}
      </div>
    </>
  );
}
