import { axiosClient } from '../context/auth';
import { AvatarData, Child, ChildPayload, ConvertToTeenPayload } from '../_models/child';
import { AvatarInfo } from '@arduino/arc/dist/components/avatar/AvatarPicker';

const apiUrl = process.env.REACT_APP_API_URL;

export async function getChildren() {
  return (await axiosClient.get<Child[]>(`${apiUrl}/v1/children/byParentID/me`)).data;
}

export async function getChild(id: string) {
  return (await axiosClient.get<Child>(`${apiUrl}/v1/children/byID/${id}`)).data;
}

export async function getRandomUsername() {
  return (await axiosClient.get<string>(`${apiUrl}/v1/children/username`)).data;
}

export async function getAvatars(): Promise<AvatarInfo[]> {
  return (await axiosClient.get<AvatarData[]>(`${apiUrl}/v1/children/avatars`)).data.map((a) => ({
    name: a.id,
    avatar: `data:image/svg+xml;base64, ${a.data}`,
  }));
}

export async function saveChild(childPayload: ChildPayload) {
  const payload = { ...childPayload, birthday: childPayload.birthday?.toISOString().split('T')[0] };
  return axiosClient.post(`${apiUrl}/v1/children/`, payload);
}

export async function confirmChild(id: string, childPayload: ChildPayload) {
  const payload = { ...childPayload, birthday: childPayload.birthday?.toISOString().split('T')[0] };
  return axiosClient.put(`${apiUrl}/v1/children/byID/${id}`, payload);
}

export async function changePassword(id: string, password: string) {
  const payload = { password };
  return axiosClient.put(`${apiUrl}/v1/children/byID/${id}/password`, payload);
}

export async function deleteChild(id: string) {
  return axiosClient.delete(`${apiUrl}/v1/children/byID/${id}`);
}

export async function convertChildToAdult(teenPayload: ConvertToTeenPayload) {
  return axiosClient.post(`${apiUrl}/v1/conversions/fromCoppa/toArduino`, teenPayload);
}
